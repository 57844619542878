import React from "react";
import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloProvider,
  gql,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  Operation,
  Observable,
  NextLink,
  FetchResult,
} from "@apollo/client";
import { isTokenValid } from "services/auth.service";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import "./i18n";
import { GlobalContextProvider } from "globalContext";

import _concat from "lodash/concat";
import _get from "lodash/get";
 
import {
  getAuthToken,
  clearToken,
  getUserFromToken,
  setAuthInfo,
} from "./utils/auth";

import typePolicies from "operations/typePolicies";

import "./index.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { FETCH_APP_DATA } from "./graphql/queries/getAppData";
import { AbilityProvider } from "utils/AbilityContext";

const authLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: typePolicies,
    },
  },
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        switch (extensions && extensions.code) {
          case "UNAUTHENTICATED": {
            // cache.reset();
            // clearToken();
          }
        }
        console.log(
          `[GraphQL error]: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      console.log(`[Network Error]: ${networkError}`);
    }
  }
);

/*
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_E4_API_ENDPOINT}/graphql`,
  // uri: 'http://e4cs.net:4000/graphql',
  credentials: "include",
  headers: {
	"client-name": "e4Cloud (web)",
	"client-version": "1.0.0",
  },
});
*/

/**************************************************
 ***  Start of Custom Fetch for upload progress  ***
 ***************************************************/

const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(":");
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(":").trim();
      headers.append(key, value);
    }
  });
  return headers;
};

export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
      };
      opts.url =
        "responseURL" in xhr
          ? xhr.responseURL
          : opts.headers.get("X-Request-URL");

      const body = "response" in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.ontimeout = () => {
      reject(new TypeError("Network request failed"));
    };
    xhr.open(options.method, url, true);

    Object.keys(options.headers).forEach((key) => {
      xhr.setRequestHeader(key, options.headers[key]);
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    options.onAbortPossible(() => {
      xhr.abort();
    });

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.useUpload) {
    console.log(options);
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_E4_API_ENDPOINT}/graphql`,
  credentials: "include",
  headers: {
    "client-name": "integrate",
    "client-version": "1.0.0",
  },
  fetch: customFetch as any,
});

/************************************************
 ***  End of Custom Fetch for upload progress  ***
 *************************************************/

interface SubscriberInterface {
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}
interface QueuedRequest {
  operation: Operation;
  forward?: NextLink;
  subscriber?: SubscriberInterface;
  observable?: Observable<FetchResult>;
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

class OperationQueuing {
  public queuedRequests: QueuedRequest[] = [];

  constructor() {
    this.queuedRequests = [];
  }

  public enqueueRequest(request: QueuedRequest): Observable<FetchResult> {
    const requestCopy = { ...request };

    requestCopy.observable =
      requestCopy.observable ||
      new Observable<FetchResult>((observer) => {
        this.queuedRequests.push(requestCopy);

        if (typeof requestCopy.subscriber === "undefined")
          requestCopy.subscriber = {};

        requestCopy.subscriber.next =
          requestCopy.next || observer.next.bind(observer);
        requestCopy.subscriber.error =
          requestCopy.error || observer.error.bind(observer);
        requestCopy.subscriber.complete =
          requestCopy.complete || observer.complete.bind(observer);
      });

    return requestCopy.observable;
  }

  public consumeQueue(): void {
    this.queuedRequests.forEach((request) => {
      // @ts-ignore
      request.forward(request.operation).subscribe(request.subscriber);
    });
    this.queuedRequests = [];
  }
}

class JwtRefreshLink extends ApolloLink {
  private fetching: boolean;

  private queue: OperationQueuing;

  constructor() {
    super();
    this.fetching = false;
    this.queue = new OperationQueuing();
  }

  isTokenValidOrUndefined() {
    const token = getAuthToken();

    if (!token) return true;

    const isValid = isTokenValid(token);

    if (isValid) return true;
    return false;
  }

  request(
    operation: Operation,
    forward: NextLink
  ): Observable<FetchResult> | null {
    if (typeof forward !== "function") {
      throw new Error(
        "[JWT Refresh Link]: JWT Refresh Link is a non terminating link and should not be the last in the composed chain"
      );
    }

    // If token does not exist, this could mean that this is not authenticated user,
    // Or the token is not expired - work as normal
    if (this.isTokenValidOrUndefined()) {
      return forward(operation);
    }

    if (!this.fetching) {
      this.fetching = true;

      const url = new URL(`${process.env.REACT_APP_E4_API_ENDPOINT}/graphql`);

      const body = {
        operationName: "refreshToken",
        variables: {
          accessToken: getAuthToken(),
        },
        query:
          "mutation refreshToken($accessToken: String!) {\n refreshToken(accessToken: $accessToken) {aspNetUserId\n refreshToken\naccessToken {token\n expiresIn\n}\n}\n}",
      };

      fetch(url.toString(), {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "client-name": "integrate",
          "client-version": "1.0.0",
        },
        body: JSON.stringify(body),
        credentials: "include",
      })
        .then((res) => res.json())
        .then((json) => {
          setAuthInfo(json.data.refreshToken);
        })
        .catch((error) => {
          // If we encounter error with token refresh
          // We need to log user out. There is no way token can be restored.
          cache.reset();
          clearToken();
        })
        .finally(() => {
          this.fetching = false;

          this.queue.consumeQueue();
        });
    }
    return this.queue.enqueueRequest({ operation, forward });
  }
}

// const httpLink = createUploadLink({
// 	uri: `${process.env.REACT_APP_E4_API_ENDPOINT}/graphql`,
// 	credentials: "include",
// 	headers: {
// 		"client-name": "Studio",
// 		"client-version": "1.0.0",
// 	},
// });

// Need ts-ignore as the types are not compatible
// @ts-ignore
const link = ApolloLink.from([
  new JwtRefreshLink(),
  authLink,
  errorLink,
  httpLink,
]);

const client = new ApolloClient({
  cache,
  link,
  // typeDefs,
  // resolvers,
});

export const FETCH_SESSION = gql`
  query fetchSession {
    session {
      UserId
    }
  }
`;

async function init() {
  client.writeQuery({
    query: gql`
      query INITIAL_DATA {
        isLoggedIn
        user {
          deletedAt
          email
          emailConfirmed
          exp
          firstName
          iat
          id
          lastName
          userName
        }
        cartItems
        settings
      }
    `,
    data: {
      isLoggedIn: !!getAuthToken(),
      user: getUserFromToken(getAuthToken()),
      cartItems: [],
      settings: {},
    },
  });

  try {
    let result = await client.query({
      query: FETCH_APP_DATA,
    });

    client.writeQuery({
      query: gql`
        query SETTINGS {
          settings
        }
      `,
      data: {
        settings: result.data.settings,
      },
    });

    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <ApolloProvider client={client}>
        <GlobalContextProvider>
          <AbilityProvider>
            <App
              isLoggedIn={!!getAuthToken()}
              settings={result.data.settings}
            />
          </AbilityProvider>
        </GlobalContextProvider>
      </ApolloProvider>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  } catch (error) {
    console.error("error fetching data ", error);
  }
}

init();
